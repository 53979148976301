import Decimal from 'decimal.js'
import { getCouponFromVariant } from '@commerce/product/use-coupon'
import { getMemberPriceFromVariant } from '@commerce/product/use-member-price'

export const normalizeStorefrontProduct = (
  item,
  result,
  coupon,
  data,
  profile
) => {
  const {
    handle,
    sku,
    name,
    imgUrl,
    mobileImgUrl,
    label,
    price,
    endTime,
    startTime,
    sellingPlanGroups,
    customAttributes = [],
    notAllowApplyCode = false,
    isFreeGift = false,
  } = item || {}

  const product = result?.products?.find(
    (p) => p?.handle?.toUpperCase() === handle?.toUpperCase()
  )

  if (product) {
    let variant = product.variants?.find(
      (variant) => variant?.sku.toUpperCase() === sku?.toUpperCase()
    )
    if (!variant) {
      variant =
        product.variants?.find((variant) => variant?.firstAvailableVariant) ||
        product.variants?.[0] ||
        {}
    }

    const discount = isFreeGift
      ? {}
      : coupon
      ? coupon
      : getCouponFromVariant(variant)

    const { onlyMemberDiscount } = getMemberPriceFromVariant({
      variant,
      coupon: discount,
      profile,
    })

    const memberDiscount = profile ? onlyMemberDiscount : 0

    const listPrice =
      variant.listPrice &&
      new Decimal(variant.listPrice)
        .minus(variant.price)
        .toFixed(0, Decimal.ROUND_DOWN)

    const discountValue =
      Math.floor(
        ((Math.abs(+discount?.fixed_value || 0) || listPrice) +
          memberDiscount) *
          100
      ) / 100

    return {
      ...item,
      label: label || product.name,
      image: imgUrl || variant?.image?.url,
      mobileImage: mobileImgUrl || imgUrl || variant?.image?.url,
      title: name || product.name,
      handle: product?.handle?.toLowerCase(),
      id: product.id,
      sku: variant.sku,
      metafields: product.metafields,
      variantMetafields: variant?.metafields,
      availableForSale: variant.availableForSale,
      basePrice: discount?.variant_price4wscode
        ? variant.price
        : variant.listPrice,
      price:
        Math.floor(
          ((discount?.variant_price4wscode ?? variant.price) - memberDiscount) *
            100
        ) / 100,
      originPrice: variant.price,
      fakePrice: data?.warmUp && price,
      discountType: discount?.value_type || 'fixed_amount',
      currencyCode: product.price?.currencyCode,
      sellingPlanGroups,
      discountValue,
      code: discount?.title,
      variantId:
        variant.id ||
        `gid://shopify/ProductVariant/${product.variant_shopify_id}`,
      brand: product.vendor,
      category: product.productType,
      tags: product.tags,
      variantTags: variant.tags,
      quantityAvailable: variant?.quantityAvailable,
      startTime: startTime || discount?.starts_at || '',
      endTime: endTime || discount?.ends_at || '',
      freeGift: variant?.metafields?.freeGift || '',
      giftImage: variant?.metafields?.infos?.gift_image,
      customAttributes,
      notAllowApplyCode,
      variant,
    }
  } else {
    return null
  }
}
