import { useCallback } from 'react'
import { getGaId } from '@lib/utils/tools'

// Extend the Window interface to include _fwn
declare global {
  interface Window {
    _fwn: {
      analytics: {
        addToCart: (data: any) => void
      }
    }
  }
}
import omit from 'lodash/omit'
import type {
  Product,
  ProductCoupons,
  VariantCoupon,
  ProductVariant,
} from '@commerce/types/product'
import { createCheckout } from 'lib/shopify/api/checkout'
import { useRouter } from 'next/router'
import { useUI } from '@components/ui'
import { createCart, useCodeApply, useCart } from 'lib/shopify/api/cart'
import { useAddItem } from 'lib/shopify/api/cart'
import { normalizeStorefrontProduct } from '@components/normalize'
import { Cart as CartType } from 'lib/shopify/types'
import { useProfile } from '@components/common/ProfileContext'
import { useCartSelectedDeliveryOptionsUpdate } from 'lib/shopify/api/cart/use-update-delivery'
import { useGlobal } from '@components/common/GlobalContext'
import { RegistrationsStatus } from '@components/common/Registrations/const'
import { normalizeCart } from 'lib/utils/normalize'

interface buynowGiftLineItemProps {
  variantId: string
  quantity: number
  customAttributes: CartType.InputArribute[]
}
interface getLineItemsProps {
  products: (Product | undefined | null)[]
  coupons?: ProductCoupons
  coupon?: VariantCoupon
  additionalCode?: string[]
  customCode?: string
  deliveryCode?: string
  customAttributes?: CartType.InputArribute[]
  buynowGiftLineItems?: buynowGiftLineItemProps[]
  callBack?: () => void
  autoApplyCode?: boolean
  buyerIdentity?: any
}

interface LineItems {
  variantId: string
  quantity: number
  customAttributes?: any[]
  sellingPlanId?: string
  weight?: number
}

interface Attribute {
  key: string
  value: string
}

function useCommerce() {
  const router = useRouter()
  const { locale } = router
  const addItem = useAddItem()
  const {
    openSidebar,
    cartSidebarMutate,
    setRegistrationsPopup,
    setRegistrationsModelStatus,
    setRegistrationsOptions,
  } = useUI()
  const codeApply = useCodeApply()
  const { profile } = useProfile()
  const clientId = getGaId()
  const cartSelectedDeliveryOptionsUpdate =
    useCartSelectedDeliveryOptionsUpdate()
  const { data } = useCart()
  const { plusMonthly, plusAnnual } = useGlobal()

  const getLineItems = ({
    products,
    coupons = {},
    coupon,
    customAttributes = [],
    autoApplyCode,
  }: getLineItemsProps) => {
    const result = {
      products,
    }
    const skuDatas: any[] = products?.map((product) =>
      normalizeStorefrontProduct(product, result, coupon, {}, profile)
    )

    const lineItems: LineItems[] = skuDatas?.map((product: any) => {
      const subscription_package_no =
        product?.metafields?.subscription_package_no
      const subscription = subscription_package_no
        ? [
            {
              key: `_appsub_${product?.sku}`,
              value: subscription_package_no,
            },
          ]
        : []
      const codeMoney = product.discountValue
        ? [
            {
              key: '_code_money',
              value: product.discountValue + '',
            },
          ]
        : []

      const cashback =
        profile && !customAttributes?.some((item) => item.key === '_login_user')
          ? [{ key: '_login_user', value: '1' }]
          : []

      const memberPriceInput = product.code
        ? [
            {
              key: '_eufy_member_price',
              value: JSON.stringify({ code: product.code }),
            },
          ]
        : []

      // 如何透传进来的product自带有customAttributes，则使用传进来的customAttributes
      // 一般是满赠的赠品才会使用自身携带的customAttributes，避免_code_money错误对当前订单总价的计算影响
      const _customAttributes = product?.customAttributes?.length
        ? product?.customAttributes
        : [
            ...customAttributes,
            ...subscription,
            ...codeMoney,
            ...cashback,
            ...memberPriceInput,
          ]
      const is_presale = JSON.parse(
        customAttributes?.find(
          (item) => item.key === '_checkout_delivery_custom'
        )?.value || '{}'
      ).is_presale

      if (is_presale) {
        _customAttributes.push({
          key: '_is_presale',
          value: 'true',
        })
      }

      return {
        variantId: String(product?.variantId),
        quantity: 1,
        customAttributes: _customAttributes.filter(
          (item: Attribute) => !['_checkout_delivery_custom'].includes(item.key)
        ),
        sellingPlanId: product?.sellingPlanId,
        weight: product?.variant?.weight,
      }
    })

    const appliedCode = () => {
      const codes: string[] = skuDatas
        ?.map((p) => !p?.notAllowApplyCode && p?.code)
        ?.filter(Boolean)
      if (codes?.length > 0) {
        return codes
      }
      return []
    }

    return {
      lineItems,
      appliedCode: autoApplyCode ? appliedCode() : [],
    }
  }

  const getLineItemsAttributes = (lineItems: LineItems[]) => {
    const attributes: CartType.InputArribute[] = []
    const appSubAttributes: CartType.subInputValue = {}
    lineItems?.map((item: LineItems) => {
      if (item?.customAttributes?.length) {
        item?.customAttributes?.map((item: CartType.InputArribute) => {
          if (item?.key?.includes('_appsub_')) {
            appSubAttributes[item.key.replace('_appsub_', '') ?? ''] =
              item?.value ?? ''
          }
        })
      }
    })
    if (Object.keys(appSubAttributes).length) {
      attributes.push({
        key: 'subscription_package_no',
        value: JSON.stringify(appSubAttributes),
      })
    }
    return attributes
  }

  const updateSelectedDeliveryOptions = useCallback(
    async (
      customAttributes: CartType.InputArribute[],
      deliveryGroups: any,
      id: string
    ) => {
      const code = JSON.parse(
        customAttributes.find(
          (item: any) => item.key === '_checkout_delivery_custom'
        )?.value || '{}'
      ).selected_delivery_option?.code
      const deliveryGroupId = deliveryGroups?.id
      const deliveryOptionHandle = deliveryGroups?.deliveryOptions?.find(
        (item: any) => item.code === code
      )?.handle
      if (deliveryGroupId && deliveryOptionHandle) {
        await cartSelectedDeliveryOptionsUpdate({
          selectedDeliveryOptions: [
            {
              deliveryGroupId,
              deliveryOptionHandle,
            },
          ],
          id,
        })
      }
    },
    [cartSelectedDeliveryOptionsUpdate]
  )

  const updatePresale = (
    customAttributes: CartType.InputArribute[],
    lineItems: LineItems[]
  ) => {
    customAttributes.forEach((item) => {
      if (item.key === '_checkout_delivery_custom') {
        const value = JSON.parse(item.value || '{}')
        value.is_presale = lineItems.some((item) =>
          item?.customAttributes?.some((item) => item.key === '_is_presale')
        )
        item.value = JSON.stringify(value)
      }
    })
  }

  const updateDeliveryCoupon = (
    codes: string[],
    customAttributes: CartType.InputArribute[]
  ) => {
    let ndd_coupon = ''
    let tdd_coupon = ''
    data?.discountAllocations?.forEach((item: any) => {
      if (item.code?.startsWith('NDD')) {
        ndd_coupon = item.code
      } else if (item.code?.startsWith('TDD')) {
        tdd_coupon = item.code
      }
    })
    const checkoutDeliveryCustom = JSON.parse(
      customAttributes.find((item) => item.key === '_checkout_delivery_custom')
        ?.value || '{}'
    )
    const { mode } = checkoutDeliveryCustom?.selected_delivery_option || {}
    if (mode === 'ndd' && tdd_coupon) {
      codes = codes.filter((code) => code !== tdd_coupon)
    } else if (mode === 'tdd' && ndd_coupon) {
      codes = codes.filter((code) => code !== ndd_coupon)
    }
  }

  const goToCheckout = useCallback(
    async (
      data: any,
      appliedCodes: string[] = [],
      useCartApi = true,
      autoApplyCode = true
    ) => {
      try {
        if (data?.lineItems?.length > 0) {
          // chenckout api does not support sellingPlanId, details see https://shopify.dev/docs/api/storefront/2024-04/input-objects/CheckoutLineItemInput
          const lineItems: LineItems[] = []
          data?.lineItems?.map((item: LineItems) => {
            lineItems.push({
              variantId: item.variantId,
              quantity: item.quantity,
              customAttributes: item.customAttributes,
              ...(useCartApi &&
                item.sellingPlanId && {
                  sellingPlanId: item.sellingPlanId,
                }),
            })
          })

          const lineItemsAttributes = getLineItemsAttributes(lineItems)

          const weight = data?.lineItems?.reduce(
            (prev: number, cur: LineItems) => {
              return (prev += (cur.weight || 0) * cur.quantity)
            },
            0
          )
          const customAttributes: CartType.InputArribute[] =
            data?.customAttributes?.map((item: any) => {
              const key = [
                '_last_url',
                '_token',
                '_checkout_delivery_custom',
                '_member_type',
                '_hide_shipping',
                '_presale',
              ].includes(item?.key)
                ? item?.key
                : item?.key.replace('_', '')
              return {
                key,
                value: item?.value,
              }
            })
          customAttributes.push({
            key: '_weight',
            value: weight.toFixed(2),
          })
          let checkoutUrl = ''
          let cart, checkout
          if (useCartApi) {
            const lines = lineItems.map((item) => ({
              ...omit(item, ['variantId', 'customAttributes', 'sellingPlanId']),
              merchandiseId: item.variantId,
              ...(item.sellingPlanId && {
                sellingPlanId: item.sellingPlanId,
              }),
              attributes: item.customAttributes,
            }))
            cart = await createCart(
              locale,
              lines,
              customAttributes
                ? customAttributes?.concat(lineItemsAttributes)
                : lineItemsAttributes,
              {
                override: false,
                buyerIdentity: data.buyerIdentity,
                autoApplyCode,
                discountCodes: appliedCodes,
              }
            )
            checkoutUrl = cart.checkoutUrl
            if (
              cart &&
              customAttributes?.some(
                (item) => item.key === '_checkout_delivery_custom'
              )
            ) {
              const _cart = normalizeCart(cart)
              try {
                await updateSelectedDeliveryOptions(
                  customAttributes,
                  _cart?.deliveryGroups?.[0],
                  cart.id
                )
              } catch (e) {
                console.log(e)
              }
            }
          } else {
            checkout = await createCheckout({
              locale: locale ?? 'us',
              lineItems,
              customAttributes: customAttributes
                ? customAttributes?.concat(lineItemsAttributes)
                : lineItemsAttributes,
              ...(!!appliedCodes && { discountCodes: appliedCodes }),
              buyerIdentity: data.buyerIdentity,
            })
            checkoutUrl = checkout?.webUrl
          }
          return { cart, checkout, checkoutUrl }
        }
        return {}
      } catch (e) {
        console.log(e)
        return {}
      }
    },
    [locale, updateSelectedDeliveryOptions]
  )

  const activeBeforeCheckout = ({
    hasMonthlyProduct,
    hasAnnualProduct,
    checkoutUrl,
    cart,
  }: {
    hasMonthlyProduct: boolean
    hasAnnualProduct: boolean
    checkoutUrl?: string
    cart?: CartType.Cart
  }) => {
    if (!profile) {
      setRegistrationsPopup(true)
      setRegistrationsOptions({
        cart,
        monthlyPlus: hasMonthlyProduct,
        annualPlus: hasAnnualProduct,
        memberRegistration: true,
        loginRedirectUrl: checkoutUrl,
      })
      setRegistrationsModelStatus(RegistrationsStatus.QUICK_LOGIN)
    }
    if (profile && !profile.activated) {
      setRegistrationsPopup(true)
      setRegistrationsModelStatus(RegistrationsStatus.AUTH_CODE)
      setRegistrationsOptions({
        cart,
        monthlyPlus: hasMonthlyProduct,
        annualPlus: hasAnnualProduct,
        memberRegistration: true,
        loginRedirectUrl: checkoutUrl,
        sendAuthCodeManually: true,
      })
    }
  }

  const buyNow = async ({
    products,
    coupon,
    coupons,
    additionalCode = [],
    customCode,
    customAttributes = [],
    buynowGiftLineItems,
    autoApplyCode = true, // 是否应用code
    callBack,
    buyerIdentity,
    // 是否使用cart api
    // cart api 支持传sellingplanId
    // 并且 createCheckout 在不久后会被废弃 @see(https://shopify.dev/changelog/deprecation-of-checkout-apis)
    useCartApi = true,
  }: getLineItemsProps & {
    useCartApi?: boolean
  }) => {
    const { lineItems, appliedCode } = getLineItems({
      products,
      coupon,
      customAttributes,
      autoApplyCode,
    })
    const codes = customCode
      ? [customCode]
      : [...appliedCode, ...additionalCode]

    customAttributes.push({
      key: '_member_type',
      value: profile?.memberType || '0',
    })
    customAttributes.find((item) => {
      if (item.key === '_checkout_delivery_custom') {
        const value = JSON.parse(item.value || '{}')
        value.is_prime = profile?.isPlus
        item.value = JSON.stringify(value)
      }
    })
    const data = {
      lineItems: lineItems.concat(buynowGiftLineItems || []),
      customAttributes,
      buyerIdentity,
    }
    const { checkoutUrl, cart } = await goToCheckout(
      data,
      codes,
      useCartApi,
      autoApplyCode
    )
    try {
      callBack && callBack()
    } catch (e) {
      console.warn(e)
    }
    const hasMonthlyProduct = products?.some(
      (item) => item?.sku && item?.sku === plusMonthly?.sku
    )
    const hasAnnualProduct = products?.some(
      (item) => item?.sku && item?.sku === plusAnnual?.sku
    )
    if ((hasMonthlyProduct || hasAnnualProduct) && !profile?.activated) {
      activeBeforeCheckout({
        hasMonthlyProduct,
        hasAnnualProduct,
        checkoutUrl,
        cart,
      })
      return
    }
    checkoutUrl && window.location.assign(checkoutUrl)
  }

  function fwntrackAddToCart(products: (Product | undefined | null)[]) {
    const totalPrice = products?.reduce((prev, cur) => {
      const variant = (cur?.variants.find((item) => item?.sku === cur?.sku) ||
        {}) as ProductVariant
      return prev + variant?.price || 0
    }, 0)
    products?.forEach((product) => {
      const variant = (product?.variants.find(
        (item) => item?.sku === product?.sku
      ) || {}) as ProductVariant

      const numericIds =
        typeof product?.id === 'string' ? product?.id.split('/').pop() : ''
      const numericId =
        typeof variant?.id === 'string' ? variant?.id.split('/').pop() : ''
      window?._fwn.analytics &&
        window?._fwn.analytics.addToCart({
          ext_customer_identifier: clientId,
          currency: product?.price?.currencyCode,
          country: locale,
          subtotal: totalPrice,
          product: [
            {
              ext_parent_product_id: numericIds,
              parent_product_name: product?.name,
              ext_product_id: numericId,
              product_name: product?.name,
              image: variant?.img?.url,
              price: variant?.price,
              currency: product?.price?.currencyCode,
              sku: product?.sku,
              quantity: 1,
            },
          ],
        })
    })
  }

  const addToCart = async ({
    products,
    coupon,
    coupons,
    additionalCode = [],
    customCode,
    autoApplyCode = true, // 是否应用code
    customAttributes = [],
    buyerIdentity,
    callBack,
  }: getLineItemsProps) => {
    try {
      const { lineItems, appliedCode } = getLineItems({
        products,
        coupon,
        customAttributes,
        autoApplyCode,
      })
      let codes = customCode
        ? [customCode]
        : [...appliedCode, ...additionalCode]

      const _customAttributes = [
        ...customAttributes,
        {
          key: '_member_type',
          value: profile?.memberType || '0',
        },
      ]
      updatePresale(_customAttributes, [
        ...lineItems,
        ...(data?.lineItems || []),
      ])

      const res = await addItem(lineItems, _customAttributes, buyerIdentity)
      if (res) {
        openSidebar()
      }
      if (
        customAttributes?.some(
          (item) => item.key === '_checkout_delivery_custom'
        )
      ) {
        try {
          await updateSelectedDeliveryOptions(
            customAttributes,
            res?.resultData?.deliveryGroups?.[0],
            res?.resultData?.id || ''
          )
        } catch (e) {
          console.log(e)
        }
        updateDeliveryCoupon(codes, customAttributes)
      }
      if (codes?.length) {
        setTimeout(() => codeApply({ discountCode: codes }))
      }
      try {
        callBack && callBack()
        fwntrackAddToCart(products?.filter(Boolean))
        cartSidebarMutate()
      } catch (e) {
        console.warn(e)
      }
      return res
    } catch (err) {
      console.log('err:', err)
    }
  }

  return { buyNow, addToCart, goToCheckout }
}

export default useCommerce
