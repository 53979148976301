import type { ProductVariant, VariantCoupon } from '@commerce/types/product'
import type { Profile } from '@commerce/types/profile'
import { useMemo } from 'react'
import {
  MEMBER_DISCOUNT_SUBJECT,
  MEMBER_DISCOUNT_TYPE,
  MEMBER_TYPE,
} from '@shopify/const'

import { useCommerce } from '@commerce/index'
import { formatCurrency } from '@lib/utils'
import { getCouponFromVariant } from '@commerce/product/use-coupon'

/**
 * @type
 * participate: boolean // 是否参与会员价
 * discount_subject: string // 折上折的作用主体 discounted_price: 折后价 , discount_amount: 折扣金额
 * price_type: string  // 普通会员价类型 fixed_amount:固定金额 percentage:百分比OFF
 * price_value: number // 普通会员价力度
 * plus_price_type: string, // 付费会员价类型 fixed_amount:固定金额 percentage:百分比OFF
 * plus_price_value: number // 付费会员价力度
 *
 */

type MemberPriceSetting = {
  participate: boolean
  discount_subject:
    | MEMBER_DISCOUNT_SUBJECT.PRICE
    | MEMBER_DISCOUNT_SUBJECT.AMOUNT
  price_type:
    | MEMBER_DISCOUNT_TYPE.FIXED_AMOUNT
    | MEMBER_DISCOUNT_TYPE.PERCENTAGE
  price_value: number | string
  plus_price_type:
    | MEMBER_DISCOUNT_TYPE.FIXED_AMOUNT
    | MEMBER_DISCOUNT_TYPE.PERCENTAGE
  plus_price_value: number | string
}

type MemberType = MEMBER_TYPE.FREE | MEMBER_TYPE.PLUS | MEMBER_TYPE.NON

type CurrencyKeys =
  | 'bestMemberDiscountWithCurrency'
  | 'bestTotalSaveWithCurrency'
  | 'bestMemberPriceWithCurrency'
  | 'memberDiscountWithCurrency'
  | 'memberTotalSaveWithCurrency'
  | 'memberPriceWithCurrency'
  | 'plusMemberDiscountWithCurrency'
  | 'plusMemberTotalSaveWithCurrency'
  | 'plusMemberPriceWithCurrency'

interface Props {
  variant?: ProductVariant
  coupon?: VariantCoupon
  profile?: Profile
  memberType?: MemberType
}

export function getMemberType({ profile }: { profile?: Profile }) {
  return profile?.memberType
    ? (profile?.memberType as MEMBER_TYPE)
    : MEMBER_TYPE.NON
}

export function getMemberDiscountType({
  memberType = MEMBER_TYPE.NON,
  setting,
}: {
  memberType?: MemberType
  setting: MemberPriceSetting
}) {
  const { price_type, price_value, plus_price_type, plus_price_value } = setting

  if (memberType === MEMBER_TYPE.PLUS) {
    return {
      type: plus_price_type,
      value: +plus_price_value,
    }
  } else if (memberType === MEMBER_TYPE.FREE) {
    return {
      type: price_type,
      value: +price_value,
    }
  } else {
    return {
      type: '',
      value: 0,
    }
  }
}

export function getMemberDiscount({
  price,
  setting,
  coupon,
  memberType,
}: {
  price: number
  coupon?: VariantCoupon
  setting: MemberPriceSetting
  memberType?: MemberType
}) {
  let priceBody = 0
  let res = 0
  // 兜底，会员折扣 + 通用折扣 <= 0.6
  const discountMax = 0.6
  const { discount_subject } = setting
  const { type, value } = getMemberDiscountType({ memberType, setting })
  const discount = getDiscount({ coupon })

  if (discount_subject === MEMBER_DISCOUNT_SUBJECT.PRICE) {
    priceBody = price - discount
  } else if (discount_subject === MEMBER_DISCOUNT_SUBJECT.AMOUNT) {
    // 如果不存在通用折扣值，则取价格本体做运算
    priceBody = discount ? discount : price
  }

  switch (true) {
    case type === MEMBER_DISCOUNT_TYPE.PERCENTAGE:
      res = Math.ceil(priceBody * value) / 100
      break
    case type === MEMBER_DISCOUNT_TYPE.FIXED_AMOUNT:
      res = value
      break
  }

  const isExcessed = (res + discount) / price > discountMax

  return isExcessed ? 0 : res
}

export function getDiscount({ coupon }: { coupon?: VariantCoupon }) {
  const { fixed_value = '0' } = coupon || {}
  return +fixed_value
}

export function getMemberPrice({
  price,
  discount,
  memberDiscount,
}: {
  price: number
  discount: number
  memberDiscount: number
}) {
  return memberDiscount > 0
    ? +(price - discount - memberDiscount).toFixed(2)
    : 0
}

export function getMemberPriceFromVariant({ variant, coupon, profile }: Props) {
  // 优化代码，减少重复调用和复杂度
  const setting = variant?.metafields?.memberPriceV2 || {}
  const price = variant?.price || 0
  const vCoupon = coupon || getCouponFromVariant(variant)
  const memberType = getMemberType({ profile })
  const discount = getDiscount({ coupon: vCoupon })
  let bestMemberDiscount = 0

  const calculateDiscount = (type: MemberType) =>
    getMemberDiscount({
      price,
      coupon: vCoupon,
      setting,
      memberType: type,
    })

  const discounts = {
    [MEMBER_TYPE.PLUS]: calculateDiscount(MEMBER_TYPE.PLUS),
    [MEMBER_TYPE.FREE]: calculateDiscount(MEMBER_TYPE.FREE),
    [MEMBER_TYPE.NON]: calculateDiscount(MEMBER_TYPE.NON),
  }

  if (memberType === MEMBER_TYPE.PLUS) {
    bestMemberDiscount =
      discounts[MEMBER_TYPE.PLUS] || discounts[MEMBER_TYPE.FREE] || 0
  } else if (memberType === MEMBER_TYPE.FREE) {
    bestMemberDiscount = discounts[MEMBER_TYPE.FREE] || 0
  }

  // const bestMemberDiscount =
  // discounts[memberType] || discounts[MEMBER_TYPE.FREE] || 0

  const calculatePrice = (memberDiscount: number) =>
    getMemberPrice({
      price,
      discount,
      memberDiscount,
    })

  const bestMemberPriceType =
    bestMemberDiscount > 0
      ? bestMemberDiscount === discounts[MEMBER_TYPE.PLUS]
        ? MEMBER_TYPE.PLUS
        : bestMemberDiscount === discounts[MEMBER_TYPE.FREE]
        ? MEMBER_TYPE.FREE
        : MEMBER_TYPE.NON
      : MEMBER_TYPE.NON

  const bestMember = {
    bestMemberPriceType,
    onlyMemberDiscount: bestMemberDiscount,
    onlyMemberPrice: calculatePrice(bestMemberDiscount),
    //  做兜底，如果不存在最佳会员折扣，则取通用折扣
    bestMemberDiscount: bestMemberDiscount ? bestMemberDiscount : discount,
    bestTotalSave: discount + bestMemberDiscount,
    // 做兜底，如果不存在会员价，则取通用折扣价
    bestMemberPrice: calculatePrice(bestMemberDiscount)
      ? calculatePrice(bestMemberDiscount)
      : price - discount,
  }

  const member = {
    memberDiscount: discounts[MEMBER_TYPE.FREE],
    memberTotalSave: discount + discounts[MEMBER_TYPE.FREE],
    memberPrice: calculatePrice(discounts[MEMBER_TYPE.FREE]),
  }

  const plusMember = {
    plusMemberDiscount: discounts[MEMBER_TYPE.PLUS],
    plusMemberTotalSave: discount + discounts[MEMBER_TYPE.PLUS],
    plusMemberPrice: calculatePrice(discounts[MEMBER_TYPE.PLUS]),
  }

  return {
    ...bestMember,
    ...member,
    ...plusMember,
  }
}

export function useMemberPrice({ variant, coupon, profile }: Props) {
  const { shop } = useCommerce()
  const memberPrices = useMemo(
    () => getMemberPriceFromVariant({ variant, coupon, profile }),
    [variant, coupon, profile]
  )

  const formattedPrices = useMemo(() => {
    const formatWithCurrency = (amount: number) =>
      formatCurrency(shop.moneyFormat, amount)

    return Object.keys(memberPrices).reduce((res, key) => {
      if (!key.includes('Type')) {
        res[`${key}WithCurrency` as CurrencyKeys] = formatWithCurrency(
          memberPrices[key as keyof typeof memberPrices] as number
        )
      }
      return res
    }, {} as Record<CurrencyKeys, string>)
  }, [shop.moneyFormat, memberPrices])

  return {
    ...memberPrices,
    ...formattedPrices,
  }
}
